@media only screen and (max-width: 699px) {
    .opportunityContent_Filters_desktop {
        display: none;
    }

    .opportunityContent_Filters_desktop_right {
        display: none;
    }

    .opportunityContent_Filters_mobile {
        width: 100vw;
        height: 9rem;
        padding: 1rem;
    }

    .cardOfFunnel {
        padding-bottom: 1rem;
        background-color: #e4e5e7;
        margin-bottom: -0.5rem;
        min-width: 23rem;
        width: 29rem;
        height: calc(100vh - 264px);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .opportunityContent_Cards {
        width: 100%;
        height: calc(100vh - 230px);
        padding: 0.5rem;
    }

    .opportunityContent_Cards_ScrollHorizontal {
        width: 95vw;
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .opportunityContent_Cards_ScrollHorizontal::-webkit-scrollbar {
        height: 8px;
        width: 4px;
        background-color: rgb(215, 216, 219);
    }

    .opportunityContent_Cards_ScrollHorizontal::-webkit-scrollbar-thumb:horizontal {
        background-color: rgba(41, 50, 65, 0.4);
        border-radius: 10px;
    }

    .opportunityContent_CardsInCard_Scroll {
        height: calc(100vh - 350px);
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        white-space: nowrap;
    }

    .opportunityContent_CardsInCard_Scroll::-webkit-scrollbar {
        width: 5px;
        border-radius: 100px;
    }

    .opportunityContent_CardsInCard_Scroll::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .opportunityContent_CardsInCard_Scroll::-webkit-scrollbar-thumb {
        background-color: rgba(41, 50, 65, 0.4);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .centralizeFromDesktop {
        display: none;
        width: 100%;
        height: 100vw;
    }

    .cardOpportunity {
        background-color: #ffff;
        width: auto;
        min-width: 20rem;
        margin: 0.3rem;
    }

    .funnelOnTable {
        display: none;
    }

    .dataTableDesktop {
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    .dataTable {
        width: calc(100vw - 2rem);
    }

    .thiscardForMobile {
        padding: 0;
    }
}

@media only screen and (min-width: 700px) {
    .taskSideRightPanel {
        height: calc(100vh - 62px);
        width: 30vw;
        position: fixed;
        z-index: 2;
        bottom: 0;
        right: 0;
        background-color: white;
        overflow-x: hidden;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transform: translate3d(100%, 0px, 0px);

        transition: transform 0.2s;
    }

    .closeTaskSideRightPanel {
        cursor: pointer;
    }

    .opportunityContent_Filters_mobile {
        display: none;
    }

    .desktopOpportunityPage {
        height: calc(100vh - 62px);
        padding: 1rem;
        margin: -30px -36px;
    }

    .desktopOpportunityContent {
        width: 99%;
        height: 99%;
        display: flex;
        flex-direction: column;
    }

    .opportunityContent_Filters {
        width: 100%;
        height: max-content;
        border-radius: 2rem;
    }

    .cardOfFunnel {
        padding-bottom: 1rem;
        background-color: #e4e5e7;
        margin-bottom: -0.5rem;
        min-width: 23rem;
        width: 29rem;
        height: 99%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .opportunityContent_Cards {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }

    .opportunityContent_Cards_ScrollHorizontal {
        width: 100%;
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .opportunityContent_Cards_ScrollHorizontal::-webkit-scrollbar {
        height: 8px;
        width: 4px;
        background-color: rgb(215, 216, 219);
    }

    .opportunityContent_Cards_ScrollHorizontal::-webkit-scrollbar-thumb:horizontal {
        background-color: rgba(41, 50, 65, 0.4);
        border-radius: 10px;
    }

    .opportunityContent_CardsInCard_Scroll {
        max-height: calc(100vh - 315px);
        min-height: calc(100vh - 530px);
        height: auto;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        white-space: nowrap;
    }

    .opportunityContent_CardsInCard_Scroll::-webkit-scrollbar {
        width: 5px;
        border-radius: 100px;
    }

    .opportunityContent_CardsInCard_Scroll::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .opportunityContent_CardsInCard_Scroll::-webkit-scrollbar-thumb {
        background-color: rgba(41, 50, 65, 0.4);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .centralizeFromDesktop {
        width: 100%;
        height: 100%;
    }

    .cardOpportunity {
        background-color: #ffff;
        width: auto;
        // max-width: 22rem;
        min-width: 22rem;
        margin: 0.3rem;
    }

    .dataTable {
        width: calc(100vw - 103px);
    }

    .toolTip {
        padding: 0.4rem !important;
        -webkit-font-smoothing: antialiased !important;
    }
}
