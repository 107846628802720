@media only screen and (max-width: 699px) {
    .desktop-opportunity-filter-menu {
        display: none;
    }

    .mobile-opportunity-filter-menu {
        display: block;

        .iconMobileFilter {
            margin-left: 4rem;
            margin-top: 0.1rem;
        }
    }
}

@media only screen and (min-width: 700px) and (max-width: 1750px) {
    .desktop-opportunity-filter-menu {
        display: block;
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 0.5rem;

        .searchFilter {
            width: 38.8vw;
            margin-top: 0.3rem;
        }
    }

    .mobile-opportunity-filter-menu {
        display: none;
    }
}

@media only screen and (min-width: 1751px) and (max-width: 1950px) {
    .desktop-opportunity-filter-menu {
        display: block;
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 0.5rem;

        .searchFilter {
            width: 50.8vw;
            margin-top: 0.3rem;
        }
    }
    .mobile-opportunity-filter-menu {
        display: none;
    }
}

@media only screen and (min-width: 1950px) {
    .desktop-opportunity-filter-menu {
        display: block;
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 0.5rem;

        .searchFilter {
            width: 60.8vw;
            margin-top: 0.3rem;
        }
    }

    .mobile-opportunity-filter-menu {
        display: none;
    }
}
