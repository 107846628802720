.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    z-index: 999999;
}

.notification-esquadros-card {
    background: #fff;
    transition: 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 30px;
    margin-bottom: 15px;
    width: 370px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 8px #999;
    opacity: 1.2;
    background-position: 15px;
    background-repeat: no-repeat;
    border: solid 0.01rem rgb(199, 196, 196);
    cursor: pointer;
    border-left-width: 10px;
    border-left-style: solid;
}

.notification-active-esquadros {
    box-shadow: 0 0 12px #fff !important;
    opacity: 1;
}

.notification-title-esquadros {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
    height: 20px;
}

.notification-message-esquadros {
    margin: 0;
    text-align: left;
    margin-left: -7px;
    height: auto;
    line-break: inherit;
}

.notification-image-esquadros {
    z-index: 0;
    position: relative;
    float: left;
    margin-right: 15px;
}

.notification-image img {
    width: 30px;
    height: 30px;
}

.toast {
    height: 50px;
    width: 365px;
    color: #fff;
    padding: 20px 15px 10px 10px;
}

a {
    color: #000000;
    text-decoration: none;
}

.notification-container button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.8;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
}

.center {
    margin-left: 40%;
    margin-top: 100%;
    white-space: pre;
}
.centerIcon {
    margin-left: 80%;
    white-space: pre;
}
