@media only screen and (max-width: 699px) {
    .desktop-opportunity-filter-menu {
        display: none;
    }

    .mobile-opportunity-filter-menu {
        display: block;

        .iconMobileFilter {
            margin-left: 4rem;
            margin-top: 0.1rem;
        }
    }

    .searchFilter {
        width: 12rem;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1750px) {
    .desktop-opportunity-filter-menu {
        display: block;
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 0.5rem;

        .multiselect {
            width: 10rem;
        }

        .searchFilterx {
            width: 43.4vw;
            margin-top: 0.3rem;
        }
    }

    .mobile-opportunity-filter-menu {
        display: none;
    }
}

@media only screen and (min-width: 1751px) and (max-width: 1950px) {
    .desktop-opportunity-filter-menu {
        display: block;
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 0.5rem;

        .calendarx {
            width: 12vw;
        }

        .multiselectx {
            width: 14.85vw;
        }

        .searchFilterx {
            width: 45.4vw;
            margin-top: 0.3rem;
        }
    }
    .mobile-opportunity-filter-menu {
        display: none;
    }
}

@media only screen and (min-width: 1950px) {
    .desktop-opportunity-filter-menu {
        display: block;
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 0.5rem;

        .calendarx {
            width: 12vw;
        }

        .multiselectx {
            width: 14.85vw;
        }

        .searchFilterx {
            width: 54.8vw;
            margin-top: 0.3rem;
        }
    }

    .mobile-opportunity-filter-menu {
        display: none;
    }
}
