:root {
    --image-atendance: var(url("../../public/assets/pages/backgroundChatAttendance.webp"));
}

div::-webkit-scrollbar-track {
    background: lightgray;
}

div::-webkit-scrollbar-thumb {
    background-color: gray;
}

div::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

@media only screen and (max-width: 699px) {
    @keyframes openSearchBar {
        0% {
            width: 0%;
        }
        100% {
            width: 80%;
        }
    }

    .viewInformationMessage {
        display: none;
    }

    .attendanceAllPage {
        margin: -32px -14px;
        height: calc(100vh - 35px);
    }

    .replyMessageBox {
        max-height: 5rem;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
        width: 90%;

        background-color: rgba(100, 105, 98, 0.2);
        border-radius: 1rem 0rem 0rem 1rem;
        margin-bottom: 0.6rem;
        padding: 0.2rem 2rem 1rem 1rem;
    }

    .replyMessageBox::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .replyMessageBox::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .replyMessageBox::-webkit-scrollbar-thumb {
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .disabled {
        display: none;
    }

    .noViewConfigurationsChat {
        display: none;
    }

    .noDialogShowSellerInformation {
        display: none;
    }

    .search {
        background-color: rgb(254, 254, 255);
        width: 100%;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
    }

    .searchInput {
        width: 99%;
    }

    .active {
        // margin-left: -5px;
        // margin-top: -4.5px;
        width: 100%;
        z-index: 999;
        position: absolute;
    }

    .chatString {
        margin-left: 0.5rem;
    }

    .rightMessageScreen {
        display: none;
    }

    .dowloadFileDocument {
        width: auto;
        height: auto;
        padding: 0.3rem 1rem 0.3rem 1rem;
        background-color: #fcfdff;
        border-radius: 1rem;
        transition: background-color 0.3s;
    }

    .dowloadFileDocument:hover {
        background-color: rgb(214, 208, 208);
    }

    .desktop-attendance-screen {
        display: block;
        width: 100vw;
        margin-left: -1rem;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: white;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
    }

    .desktop-attendance-screen::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .desktop-attendance-screen::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .desktop-attendance-screen::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, darkgrey 0%, darkgrey 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .leftMessageScreen {
        border-right: solid;
        height: calc(100vh - 67px);
    }

    .headerChatMobile {
        width: 100%;
        height: 8vh;
        background-color: rgb(240, 242, 245);
        display: flex;
        align-items: center;
    }

    .messagesBodyContact {
        background-color: #f7f7f8;
        box-shadow: inset 0 0 0.2em #d7d8db, 0 0 0;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
        width: 100%;
        flex: 1;
        background: url("../../public/assets/pages/backgroundChatAttendance.webp");
    }

    .messagesBodyContact::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .messagesBodyContact::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .messagesBodyContact::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, darkgrey 0%, darkgrey 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .inputTextInHeaderContact {
        width: 80vw;
        border-radius: 0.8rem;
        transition-duration: 4s;
        animation-name: openSearchBar;
        animation-duration: 0.8s;
    }

    .textMessageClient {
        width: 100%;
    }

    .desktop-view {
        overflow: hidden;
        margin-right: 0;
        padding: 0 0 0 0;
        height: calc(100vh - 37px);
    }

    .desktop {
        height: 100%;
    }

    .desktopPageAttendance {
        height: 100%;
    }

    .contentDesktopPage {
        height: 100%;
    }

    .contactcontact {
        z-index: 99999999;
        width: 100%;
        height: auto;
        min-height: 4.5rem;
        max-height: 5rem;
        background-color: white;
        padding: 1.5rem 0rem 1.5rem 0rem;
        border-top: 0.5px solid #ededed;
    }
    .contactcontact:hover {
        background-color: rgb(232, 228, 228);
    }

    .p-button-microphone:active {
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        transform: translateY(1.9px);
    }

    .sendMessage {
        width: 100%;
        background-color: rgb(240, 242, 245);

        box-sizing: border-box;
        padding-top: 0.2rem;
        margin-bottom: -1rem;
        padding-bottom: -2rem;
        padding-left: 2rem;
        // display: flex;
        flex-direction: row;
        align-items: flex-end;
        max-width: 100%;
        min-height: 62px;
        gap: 10px;
    }

    .inputSendMessage {
        width: 66%;
        min-height: 2em;
    }

    .mobile-opportunity-filter-menu {
        display: none;
    }

    .positionTavView {
        width: 100vw;
    }
    .noShowInformationAboutSellerWhoResponded {
        display: none;
    }

    .avatarResponsibleChatOnTabAllConversations:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 0px 1px;
    }

    #w-input-container {
        margin-right: 0.5rem;
        padding: 0.7rem 0.3rem 0.7rem 0.7rem;
        outline: none;
        border-radius: 0.8rem;
        border: none;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        width: 70vw;
    }

    .w-input-text-group {
        position: relative;
    }

    .w-placeholder {
        color: #a0a0a0;
        top: 0;
        pointer-events: none;
        user-select: none;
        position: absolute;
        opacity: 0;
        transition: 0.2s padding ease-in-out;
    }

    #w-input-text {
        overflow-x: hidden;
        overflow-y: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        z-index: 1;
        max-height: 100px;
        min-height: 20px;
        padding: 0 0 0 2px;
        outline: 0;
        transition: 0.2s padding ease-in-out;
    }

    #w-input-text:empty + .w-placeholder {
        opacity: 1;
    }

    #w-input-text:focus + .w-placeholder {
        padding: 0 6px 0px;
    }

    #w-input-text:focus {
        padding: 0 6px 0px;
    }

    .tabViewScrollabe {
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
    }

    .tabViewScrollabe::-webkit-scrollbar {
        width: 0rem;
        border-radius: 100px;
    }

    .tabViewScrollabe::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .tabViewScrollabe::-webkit-scrollbar-thumb {
        box-shadow: inset 2px 2px 5px 0 rgba(darkgrey, 0.5);
        border-radius: 100px;
    }

    .rightSideEmpty {
        display: none;
    }

    .leftSideOfPage {
        width: 100%;
        height: 100%;
    }

    .rightSkeletonPage {
        display: none;
    }

    .leftSkeleton {
        padding: 1rem;
        height: 100%;
        width: 100vw;
    }
}

@media only screen and (min-width: 700px) {
    @keyframes openSearchBar {
        0% {
            width: 0%;
        }
        100% {
            width: 70%;
        }
    }

    @keyframes openInformationCompany {
        0% {
            width: 0%;
        }
        100% {
            width: 30vw;
        }
    }

    @keyframes searchMessage {
        from {
            background-color: rgba(255, 255, 255, 0.3);
        }

        to {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    @keyframes opacitySelectMessageReply {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 0.3;
        }
    }

    @keyframes opacitySelectMessageReply {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 0.3;
        }
    }

    .noViewInformationMessage {
        display: none;
    }

    .viewInformationMessage {
        margin-top: -0.5rem;
    }

    .noViewConfigurationsChat {
        display: none;
    }

    .avatarDialogInformation {
        margin-top: -2rem;
    }

    .dowloadFileDocument {
        width: auto;
        height: auto;
        padding: 0.3rem 1rem 0.3rem 1rem;
        background-color: #fcfdff;
        border-radius: 1rem;
        transition: background-color 0.3s;
    }

    .dowloadFileDocument:hover {
        background-color: rgb(214, 208, 208);
    }

    .dialogShowDetailMessageInformation {
        margin-top: 0.5rem;
        display: block;
        width: 30vw;
        padding: 1rem;
        height: calc(100vh - 126px);
        background-color: white;
        position: fixed;
        right: 0;
        z-index: 999999999999;
        animation-name: openInformationCompany;
        animation-duration: 0.6s;
    }

    .dialogShowDetailMessageInformation::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .dialogShowDetailMessageInformation::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .dialogShowDetailMessageInformation::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, darkgrey 0%, darkgrey 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .dialogShowClientInformation {
        display: block;
        width: 30vw;
        height: calc(100vh - 126px);
        background-color: white;
        position: fixed;
        right: 0;
        z-index: 999999999999;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
        animation-name: openInformationCompany;
        animation-duration: 0.6s;
    }

    .dialogShowClientInformation::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .dialogShowClientInformation::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .dialogShowClientInformation::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, darkgrey 0%, darkgrey 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .scrollShowClientInformation {
        display: block;
        width: auto;
        margin-left: -1rem;
        height: calc(100% - 275px);
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
        margin-bottom: 5rem;
    }

    .scrollShowClientInformation::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .scrollShowClientInformation::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .scrollShowClientInformation::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, darkgrey 0%, darkgrey 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .noDialogShowClientInformation {
        display: none;
    }

    .showInformationAboutSellerWhoResponded {
        display: block;
        width: 30vw;
        padding: 1rem;
        height: calc(100vh - 126px);
        background-color: white;
        position: fixed;
        right: 0;
        z-index: 999999999999;
        animation-name: openInformationCompany;
        animation-duration: 0.6s;
    }

    .noShowInformationAboutSellerWhoResponded {
        display: none;
    }

    .oportunitiesCompany {
        width: auto;
        height: auto;
        // max-height: 5rem;
        margin-bottom: 0.2rem;
        background-color: white;
        padding: 0.3rem;
    }

    .disabled {
        display: none;
    }

    .active {
        display: none;
    }

    .desktop-attendance-screen {
        display: block;
        width: 100%;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #d7d8db;
    }

    .desktop-attendance-screen::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .desktop-attendance-screen::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .desktop-attendance-screen::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, darkgrey 0%, darkgrey 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .leftMessageScreen {
        // height: calc(100vh - 95px);
        font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu, Cantarell, Fira Sans, sans-serif;

        .mouseInOptionsChat:hover {
            font-weight: 600;
        }
    }

    .p-tabview-panels {
        max-height: 100%;
    }

    .headerUser {
        height: 10%;
    }

    .rightMessageScreen {
        background-color: rgb(240, 242, 245);
    }

    .imageSend {
        background-color: rgba(41, 50, 65, 0.1);
        border-radius: 10px;
        display: flex !important;
        justify-content: center;
    }

    .replyMessageBox {
        max-height: 4rem;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
    }

    .replyMessageBox::-webkit-scrollbar {
        width: 3px;
        border-radius: 100px;
    }

    .replyMessageBox::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .replyMessageBox::-webkit-scrollbar-thumb {
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }

    .messagesBodyContact {
        background-color: #f7f7f8;
        box-shadow: inset 0 0 0.2em #d7d8db, 0 0 0;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
        width: 100%;
        flex: 1;
        background: --image-atendance;
    }

    .messagesBodyContact::-webkit-scrollbar {
        width: 0.3rem;
        border-radius: 100px;
    }

    .messagesBodyContact::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .messagesBodyContact::-webkit-scrollbar-thumb {
        box-shadow: inset 2px 2px 5px 0 rgba(darkgrey, 0.5);
        border-radius: 100px;
    }

    .inputTextInHeaderContact {
        width: 70%;
        border-radius: 0.8rem;
        transition-duration: 4s;
        animation-name: openSearchBar;
        animation-duration: 0.8s;
    }

    .textMessageClient {
        width: calc(14vw);
    }

    .desktop-view {
        margin-bottom: -3rem;
    }

    .contactcontact {
        z-index: 99999999;
        width: 100%;
        height: auto;
        min-height: 4.5rem;
        max-height: 5rem;
        background-color: white;
        border-top: 0.5px solid #ededed;
    }

    .contactcontact:hover {
        background-color: rgb(242, 244, 246);

        .responsible {
            opacity: 0.25;
        }
    }

    .responsible {
        opacity: 0;
    }

    .p-button-microphone:active {
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        transform: translateY(1.9px);
    }

    .sendMessage {
        width: 100%;
        background-color: rgb(240, 242, 245);
        padding: 0.25rem;
        box-sizing: border-box;
        flex-direction: row;
        max-width: 100%;
        gap: 10px;
    }

    .inputSendMessage {
        min-height: 2.8em;
    }

    .mobile-opportunity-filter-menu {
        display: none;
    }

    .animationSearchMessage {
        background-color: rgba(255, 255, 255, 0.1);
        height: auto;
        animation: searchMessage 3s;
        border-radius: 1rem;
    }

    .sendTextInputMessage {
        padding: 0.5rem 0.5rem 0.5rem 0.7rem;
        resize: none;
        outline: none;
        border-radius: 0.4rem;
        line-height: 1.5rem;
        border: none;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 0.2px;
        width: 100%;
        font-size: 1.1rem;
    }

    .iconReplyMessage {
        animation-name: opacitySelectMessageReply;
        animation-duration: 0.2s;
        opacity: 0.3;
    }

    .search {
        background-color: white;
        width: 100%;
        padding: 0.5rem;
    }

    .searchInput {
        width: 100%;
    }

    .p-tabview .p-tabview-nav-btn.p-link {
        background: rgba(255, 255, 255, 0.6) !important;
        color: #2196f3;
        // width: 1rem !important;
        box-shadow: none !important;
        border-radius: 0;
    }

    .p-tabview .p-tabview-panels {
        padding: 0rem;
    }

    .p-tabview-nav-btn {
        position: absolute;
        top: 0rem !important;
        z-index: 2;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu {
        font-size: 14px;
        background-color: #fff;
        border: 0.1px solid rgba(120, 120, 120, 0.15);
        padding: 1rem 1rem 1rem 1rem;
        width: auto;
        height: auto;
        margin: 0;
        z-index: 10;
        position: absolute;
        list-style: none;
        opacity: 1;
        transition: opacity 0.5s linear;
        cursor: pointer;
    }

    .divider {
        margin: 0.5rem;
    }

    .p-datascroller .p-datascroller-content {
        background: url("./../../public/assets/pages/backgroundChatAttendance.webp") !important;
    }

    .buttonFetchMoreMessages {
        text-align: center;
        padding: 0.3rem;
        opacity: 0.4;

        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
    }

    .buttonFetchMoreMessages:hover {
        opacity: 0.9;
    }

    .bubbleMessageCardSent {
        padding: 0.1rem;
        border: 0.01rem solid #e4e5e7;
        border-radius: 0.8rem 0rem 0.8rem 0.8rem;
        box-shadow: 0.04rem 0.04rem 0.06rem #d7d8db;
        margin-right: 0.5rem;
        position: relative;
        z-index: 0;
    }

    .bubbleMessageCardSent:before {
        border-radius: 0rem 0.3rem 0rem 0rem;

        z-index: -2;
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 3px solid #d9fdd3;
        border-right: 10px solid transparent;
        border-top: 10px solid #d9fdd3;
        border-bottom: 10px solid transparent;
        right: -10px;
        top: 0rem;
    }

    .bubbleMessageCardReceived {
        padding: 0.1rem;
        border: 0.01rem solid #e4e5e7;
        border-radius: 0rem 0.8rem 0.8rem 0.8rem;
        box-shadow: 0.04rem 0.04rem 0.06rem #d7d8db;
        position: relative;
        z-index: 0;
    }

    .bubbleMessageCardReceived:before {
        border-radius: 0.3rem 0rem 0rem 0rem;

        z-index: -2;
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 3px solid white;
        border-top: 10px solid white;
        border-bottom: 10px solid transparent;
        left: -10px;
        top: 0rem;
    }

    .addBorder {
        border: solid #f0f2f5;
    }

    .avatarResponsibleChatOnTabAllConversations:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 0px 1px;
    }

    #w-input-container {
        margin-right: 0.5rem;
        padding: 0.55rem 0.3rem 0.55rem 0.55rem;
        outline: none;
        border-radius: 0.8rem;
        border: none;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        // min-width: 52rem;
        // width: 66.5vw;
    }

    .w-input-text-group {
        position: relative;
    }

    .w-placeholder {
        color: #a0a0a0;
        top: 0;
        pointer-events: none;
        user-select: none;
        position: absolute;
        opacity: 0;
        transition: 0.2s padding ease-in-out;
    }

    #w-textAreaDesktop {
        overflow-x: hidden;
        overflow-y: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        z-index: 1;
        max-height: 100px;
        min-height: 20px;
        padding: 0 0 0 2px;
        outline: 0;
        transition: 0.2s padding ease-in-out;
    }

    #w-textAreaDesktop:empty + .w-placeholder {
        opacity: 1;
    }

    #w-textAreaDesktop:focus + .w-placeholder {
        padding: 0 6px 0px;
    }

    #w-textAreaDesktop:focus {
        padding: 0 6px 0px;
    }

    #textForDialogImageOrVideo {
        overflow-x: hidden;
        overflow-y: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        z-index: 1;
        max-height: 100px;
        min-height: 20px;
        padding: 0 0 0 2px;
        outline: 0;
        transition: 0.2s padding ease-in-out;
    }

    #textForDialogImageOrVideo:empty + .w-placeholder {
        opacity: 1;
    }

    #textForDialogImageOrVideo:focus + .w-placeholder {
        padding: 0 6px 0px;
    }

    #textForDialogImageOrVideo:focus {
        padding: 0 6px 0px;
    }

    #textForDialogImageOrVideo1 {
        overflow-x: hidden;
        overflow-y: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        z-index: 1;
        max-height: 100px;
        min-height: 20px;
        padding: 0 0 0 2px;
        outline: 0;
        transition: 0.2s padding ease-in-out;
    }

    #textForDialogImageOrVideo1:empty + .w-placeholder {
        opacity: 1;
    }

    #textForDialogImageOrVideo1:focus + .w-placeholder {
        padding: 0 6px 0px;
    }

    #textForDialogImageOrVideo1:focus {
        padding: 0 6px 0px;
    }

    .tabViewScrollabe {
        height: calc(100vh - 325px);
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
    }

    .tabViewScrollabe::-webkit-scrollbar {
        width: 0rem;
        border-radius: 100px;
    }

    .tabViewScrollabe::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .tabViewScrollabe::-webkit-scrollbar-thumb {
        box-shadow: inset 2px 2px 5px 0 rgba(darkgrey, 0.5);
        border-radius: 100px;
    }

    .desktopPageAttendance {
        display: flex;
        justify-content: center;
        height: calc(100vh - 120px);
    }

    .contentDesktopPage {
        width: 100%;
        max-width: 200rem;
        height: 100%;
        padding: 0.2rem;
    }

    .leftSideOfPage {
        width: 25%;
        min-width: 30rem;
        height: 100%;
    }

    .leftSkeleton {
        margin-left: 1rem;
        margin-top: 1rem;
        height: 100%;
    }

    .imagePreviewDialogHorizontalScroll {
        width: 100%;
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .imagePreviewDialogHorizontalScroll::-webkit-scrollbar {
        height: 8px;
        width: 4px;
        background-color: rgb(215, 216, 219);
    }

    .imagePreviewDialogHorizontalScroll::-webkit-scrollbar-thumb:horizontal {
        background-color: rgba(41, 50, 65, 0.4);
        border-radius: 10px;
    }

    .defaultMessageShortcutAttendance {
        width: 100%;
        max-height: 13rem;
        background-color: #f2f4f6;

        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
    }

    .defaultMessageShortcutAttendance::-webkit-scrollbar {
        width: 7px;
        border-radius: 100px;
    }

    .defaultMessageShortcutAttendance::-webkit-scrollbar-track {
        background-color: rgb(215, 216, 219);
        border-radius: 100px;
    }

    .defaultMessageShortcutAttendance::-webkit-scrollbar-thumb {
        background-color: rgba(darkgrey, 0.8);
        border-radius: 100px;
    }

    .messageShortcutAttendance {
        display: flex;
        width: 95%;

        background-color: white;
        padding: 10px;

        border-radius: 10px;

        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
        margin-top: 8px;

        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.2) 0px 1px 2px;
        transition: 0.5s;
    }

    .messageShortcutAttendance:hover {
        cursor: pointer;
        background-color: #f3f2f2;
    }

    .dialogShowAttachments {
        height: 100vh !important;
        min-height: 100vh !important;
    }
}

audio::-webkit-media-controls-panel {
    background-color: #989898;
}

.keyboard-key {
    background-color: #dfdfdf;
    padding: 0.4rem;
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;

    padding-right: 0.5rem;
    margin-right: 0.3em;
    margin-bottom: 0.2rem;
    font-size: small;
    text-align: center;
    box-shadow: 0 5px 0 #c8c8c8;
}
