:root {
    --white: #e6e6e6;
    --light-white: #ffffff99;

    --gray: #202c33;
    --light-gray: #2a3942;
    --dark-gray: #202c33;
    --gray-text: #8696a0;
    --light-gray-text: #8696a025;
    --light-gray-hover: #8696a015;

    --black: #0b141a;

    --green: #00a884;
    --dark-green: #005c4b;
}

.layout-dashboard {
    .orders {
        h4 {
            margin-bottom: 20px;
        }

        .p-button {
            margin-top: -20px;
        }

        .order-tabs {
            margin-bottom: 1rem;

            .order-tab {
                padding: 1rem 2rem 2rem 1rem;
                position: relative;
                transition: box-shadow 0.2s;

                &:hover {
                    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
                    cursor: pointer;
                }

                i {
                    font-size: 1rem;
                    vertical-align: middle;
                }

                .order-label {
                    margin-left: 0.25rem;
                    vertical-align: middle;
                }

                .stat-detail-icon {
                    position: absolute;
                    right: 1rem;
                    top: 2.25rem;
                    height: 1rem;
                    width: 1rem;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    width: 90%;
                }
            }
        }
    }

    .overview-chart {
        overflow: auto;
    }

    .dashbard-demo-dropdown {
        min-width: 8rem;
        margin-left: auto;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }

        &.status-custom1 {
            background: #b1b0fb;
            color: #565151;
        }
    }
}

.datatable-demo {
    .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td:last-child .p-column-title {
        display: none;
    }
}

.carousel-demo {
    .p-carousel {
        .p-carousel-content {
            .p-carousel-item {
                .car-details {
                    > .p-grid {
                        border-radius: 3px;
                        margin: 0.3em;
                        text-align: center;
                        padding: 2em 0 2.25em 0;
                    }
                }

                .car-data {
                    .car-title {
                        font-weight: 700;
                        font-size: 20px;
                        margin-top: 24px;
                    }

                    .car-subtitle {
                        margin: 0.25em 0 2em 0;
                    }

                    button {
                        margin-left: 0.5em;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.dataview-demo {
    .p-dataview {
        .car-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2em;

            & > div {
                display: flex;
                align-items: center;

                img {
                    margin-right: 14px;
                }
            }
        }

        .car-detail {
            padding: 0 1em 1em 1em;
            margin: 1em;
        }
    }
}

@media (max-width: 1024px) {
    .dataview-demo {
        .p-dataview {
            .car-details {
                img {
                    width: 75px;
                }
            }
        }
    }
}

.floatlabel-demo {
    .p-field {
        margin-top: 2rem;
        margin-bottom: 0;
    }
}

.input-demo {
    .p-multiselect {
        min-width: 15rem;
    }

    .multiselect-custom {
        .country-item {
            display: flex;
            align-items: center;

            span.flag {
                width: 18px;
                height: 12px;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }
        }

        .country-item-value {
            border-radius: 3px;
            display: inline-flex;
            margin-right: 0.5rem;
            background-color: #2196f3;
            color: #ffffff;
        }
    }
}

.list-demo {
    .product-name {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .product-description {
        margin: 0 0 1rem 0;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .product-category {
        font-weight: 600;
        vertical-align: middle;
    }

    .product-list-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;

        img {
            width: 150px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .product-list-detail {
            flex: 1 1 0;
        }

        .p-rating {
            margin: 0 0 0.5rem 0;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            align-self: flex-end;
        }

        .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: 0.5rem;
        }
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }

        &.status-custom1 {
            background: #b1b0fb;
            color: #565151;
        }
    }

    .product-grid-item {
        margin: 0.5em;
        border: 1px solid var(--surface-d);

        .product-grid-item-top,
        .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 75%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 2rem 0;
        }

        .product-grid-item-content {
            text-align: center;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 576px) {
        .product-list-item {
            flex-direction: column;
            align-items: center;

            img {
                width: 75%;
                margin: 2rem 0;
            }

            .product-list-detail {
                text-align: center;
            }

            .product-price {
                align-self: center;
            }

            .product-list-action {
                display: flex;
                flex-direction: column;
            }

            .product-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
}

.media-demo {
    .product-item {
        .product-item-content {
            border: 1px solid var(--surface-d);
            border-radius: 3px;
            margin: 0.3rem;
            text-align: center;
            padding: 2rem 0;
        }

        .product-image {
            width: 50%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }
    }
}

.menu-demo {
    .stepsdemo-content {
        padding: 1em 0;

        p {
            font-weight: 400;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            margin: 0;
        }

        i {
            vertical-align: middle;
            font-size: 1.5em;
            margin: 0;
        }
    }

    .contextmenu-image {
        width: 100%;
    }
}

.messages-demo {
    .p-field > label {
        width: 125px;
    }

    .p-inputtext {
        margin-right: 0.5rem;
    }
}

.misc-demo {
    .badges {
        .p-badge,
        .p-tag {
            margin-right: 0.5rem;
        }
        .p-tag {
            line-height: 1;
        }
    }

    .p-button {
        margin-right: 0.5rem;
    }

    .p-overlay-badge {
        margin-right: 2rem;
    }
}

.overlay-demo {
    p {
        line-height: 1.5;
        margin: 0;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.panel-demo {
    .p-toolbar {
        flex-wrap: wrap;
        overflow: auto;
    }

    p {
        line-height: 1.5;
        margin: 0;
    }

    .p-card {
        .p-card-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
            padding: 1rem 1rem 0 1rem;
        }

        .p-card-body {
            padding-top: 0;
        }
    }
}

.table-demo {
    .p-progressbar {
        height: 0.5rem;
        background-color: #d8dadc;

        .p-progressbar-value {
            background-color: #607d8b;
        }
    }

    .p-datatable .p-column-filter {
        display: none;
    }

    .table-header {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .p-datatable.p-datatable-customers {
        .p-datatable-tbody {
            > tr {
                border-bottom: 1px solid var(--surface-d);
            }
        }

        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead > tr > th {
            text-align: left;
        }

        .p-datatable-tbody > tr > td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }

    /* Responsive */
    .p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
        display: none;
    }

    .customer-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-qualified {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-unqualified {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-negotiation {
            background: #feedaf;
            color: #8a5340;
        }

        &.status-new {
            background: #b3e5fc;
            color: #23547b;
        }

        &.status-renewal {
            background: #eccfff;
            color: #694382;
        }

        &.status-proposal {
            background: #ffd8b2;
            color: #805b36;
        }
    }

    .p-progressbar-value.ui-widget-header {
        background: #607d8b;
    }

    @media (max-width: 640px) {
        .p-progressbar {
            margin-top: 0.5rem;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .orders-subtable {
        padding: 1rem;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }

        &.status-custom1 {
            background: #b1b0fb;
            color: #565151;
        }
    }

    .order-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.order-delivered {
            background: #c8e6c9;
            color: #256029;
        }

        &.order-cancelled {
            background: #ffcdd2;
            color: #c63737;
        }

        &.order-pending {
            background: #feedaf;
            color: #8a5340;
        }

        &.order-returned {
            background: #eccfff;
            color: #694382;
        }
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    > td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }
                    }
                }

                colgroup {
                    display: none;
                }
            }

            .p-datatable-header {
                .table-header {
                    flex-direction: column !important;
                }
            }
        }
    }
}

.text-demo {
    .demo-container {
        border: 2px solid #dee2e6;
    }
}

.spacing-demo {
    .demo-container {
        border: 1px solid #dee2e6;
    }
}

.icons-demo {
    .icon-filter {
        width: 100%;
        padding: 1rem;
        margin: 1rem 0 1.5rem 0;
    }

    .icons-list {
        text-align: center;
        color: #6c757d;

        .p-md-2 {
            padding: 1em;
        }
    }

    .icons-list i {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }
}

.flexgrid-demo {
    .box {
        background-color: var(--surface-e);
        text-align: center;
        padding: 1.25rem;
        font-size: 1.5rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    .box-stretched {
        height: 100%;
    }

    .vertical-container {
        margin: 0;
        height: 200px;
        background: var(--surface-d);
        border-radius: 4px;
    }

    .nested-grid .p-col-4 {
        padding-bottom: 1rem;
    }

    .dynamic-box-enter-active,
    .dynamic-box-leave-active {
        transition: all 0.5s;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        opacity: 0;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        transform: translateX(30px);
    }

    p {
        margin: 0;
    }
}

.flexbox-demo {
    .p-d-flex > div,
    .box {
        background-color: var(--surface-e);
        text-align: center;
        padding: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    .p-d-flex > div {
        width: 8rem;
    }
}

.elevation-demo {
    .box {
        min-height: 100px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        margin: 2rem;
        border-radius: 4px;
    }
}

.crud-demo {
    .table-header {
        flex-direction: column;
        @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row !important;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-toolbar {
        display: flex;
        flex-wrap: wrap;
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }

        &.status-custom1 {
            background: #b1b0fb;
            color: #565151;
        }
    }

    /* Responsive */
    .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.datatable-responsive {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    border-bottom: 1px solid var(--surface-d);
                    > td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }

                        .actions {
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.docs {
    h1 {
        margin-top: 28px;
        margin-bottom: 14px;
    }

    p {
        line-height: 1.5;
    }

    li {
        line-height: 1.5;
    }
}

pre[class*="language-"] {
    &:before,
    &:after {
        display: none !important;
    }

    code {
        border-left: 10px solid var(--surface-d) !important;
        box-shadow: none !important;
        background: var(--surface-b) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;

        .token {
            &.tag,
            &.keyword {
                color: #2196f3 !important;
            }

            &.attr-name,
            &.attr-string {
                color: #2196f3 !important;
            }

            &.attr-value {
                color: #4caf50 !important;
            }

            &.punctuation {
                color: var(--text-color);
            }

            &.operator,
            &.string {
                background: transparent;
            }
        }
    }
}

.timeline-demo {
    .custom-marker {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;
    }

    .p-timeline-event-content,
    .p-timeline-event-opposite {
        line-height: 1;
    }

    @media screen and (max-width: 960px) {
        .customized-timeline {
            .p-timeline-event:nth-child(even) {
                flex-direction: row !important;

                .p-timeline-event-content {
                    text-align: left !important;
                }
            }

            .p-timeline-event-opposite {
                flex: 0;
            }

            .p-card {
                margin-top: 1rem;
            }
        }
    }
}

// Css personalizado.

.content-plus-crm {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
}
