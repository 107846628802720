.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 200px;
}

.controls {
    position: absolute;
    bottom: 120px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 75px;
    display: flex;
    align-items: center;
}

.controls2 {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 75px;
    display: flex;
    align-items: center;
}

.slider {
    padding: 22px 0px;
}

.zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 12px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #0c1546;
    width: 200%;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 30px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(0, 36, 237, 0.16);
    border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(7, 43, 245, 0.16);
}
