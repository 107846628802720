@media only screen and (max-width: 1000px) {
    .react-pdf__Page__canvas {
        max-width: 100%;
        height: auto !important;
        margin-bottom: 0.5rem;
        overflow: hidden;
    }

    .modal-main-styling {
        width: 100vw;
        height: 100vh;
    }

    .apresentation-proposal-moblie {
        overflow-x: hidden;
    }

    .mainStyling {
        width: 100%;
        height: 100%;
        iframe {
            width: 100%;
            height: 100%;
            zoom: 35%;
            border: 0;
        }
    }

    .apresentation-proposal-desktop {
        display: none;
    }
}

@media only screen and (min-width: 1001px) {
    .react-pdf__Page__canvas {
        max-width: 100%;
        height: auto !important;
    }

    .modal-main-styling {
        width: 80vw;
        height: 100vh;
    }

    .mainStyling {
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
            zoom: 100%;
            border: 0;
        }
    }

    .apresentation-proposal-moblie {
        display: none;
    }

    .apresentation-proposal-desktop {
        width: 100vw;
        height: 95vh;

        iframe {
            width: 100%;
            height: 100%;
            overflow: hidden;
            zoom: 100%;
            border: 0;
        }
    }

    .preferences-esqaudros {
        width: 90vw;
        height: 100vh;
        iframe {
            width: 100%;
            height: 100%;
            overflow: hidden;
            zoom: 100% !important;
            border: 0;
        }
    }
}

.apresentation-body-desktop {
    background-color: #333333;
    overflow-x: hidden;
}

.apresentation-body-mobile {
    background-color: #333333;
    overflow-x: hidden;
}
