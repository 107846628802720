@media only screen and (max-width: 699px) {
    .desktopTag {
        display: none;
    }

    .cardOfAttachment {
        border-radius: 10px;
        background-color: #f2f4f6;
        height: fit-content;
        padding: 0.2rem;

        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

        position: relative;
    }

    .imageContent {
        border-radius: 10px;

        cursor: pointer;
    }

    .videoContent {
        border-radius: 10px;
    }

    .selectBox {
        margin-left: -10px;
        margin-top: -10px;
        width: 25px;
        height: 25px;
        border-radius: 50px;
        background-color: white;
        position: absolute;
        z-index: 99;
        cursor: pointer;

        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }

    .selectBox-video {
        margin-left: -12px;
        margin-top: -10px;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        background-color: white;
        position: absolute;
        z-index: 99;

        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

        cursor: pointer;
    }

    .buttonToSendArray {
        display: flex;
        position: fixed;
        bottom: 1rem;
        right: 2.5rem;
    }

    .rightSideOfPage {
        width: 100%;
        max-height: calc(100vh - 141px);

        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
    }

    .rightSideOfPage::-webkit-scrollbar {
        width: 10px;
        border-radius: 100px;
    }

    .rightSideOfPage::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
        border-radius: 100px;
    }

    .rightSideOfPage::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 100px;
    }
}

@media only screen and (min-width: 700px) {
    .mobileTag {
        display: none;
    }

    .columnsDataTable--DialogToDataAllGroups {
        transition: 0.15s;
    }

    .columnsDataTable--DialogToDataAllGroups:hover {
        background-color: rgb(240, 242, 245);

        cursor: pointer;
    }

    .cardOfAttachment {
        border-radius: 10px;
        background-color: #f2f4f6;
        height: fit-content;
        padding: 0.2rem;

        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

        position: relative;
    }

    .imageContent {
        border-radius: 10px;

        cursor: pointer;
    }

    .videoContent {
        border-radius: 10px;
    }

    .selectBox {
        margin-left: -10px;
        margin-top: -10px;
        width: 25px;
        height: 25px;
        border-radius: 50px;
        background-color: white;
        position: absolute;
        z-index: 99;
        cursor: pointer;

        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }

    .selectBox-video {
        margin-left: -12px;
        margin-top: -10px;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        background-color: white;
        position: absolute;
        z-index: 99;

        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

        cursor: pointer;
    }

    .buttonToSendArray {
        display: flex;
        position: fixed;
        bottom: 1rem;
        right: 2.5rem;
    }

    .rightSideOfPage {
        width: 100%;
        max-height: calc(100vh - 141px);

        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e0e0e0;
    }

    .rightSideOfPage::-webkit-scrollbar {
        width: 10px;
        border-radius: 100px;
    }

    .rightSideOfPage::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
        border-radius: 100px;
    }

    .rightSideOfPage::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 100px;
    }
}
