@media only screen and (max-width: 699px) {
    .leftFilters {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .filter {
        margin-bottom: 0.5rem;
    }

    .rigthFilter {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 700px) {
    .filters {
        display: flex;
        justify-content: space-between;
    }

    .filter {
        margin-right: 1rem;
    }
}
