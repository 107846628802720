@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;800;900&display=swap");

@media only screen and (max-width: 699px) {
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .banner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
    }

    .image {
        display: none;
    }

    .animationBackground {
        padding: 3rem 1rem 1rem 1rem;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100vw;
        height: 100%;
        background: linear-gradient(271deg, #101063, #000045, #1717b4, #1d1d5d, #242471, #1717b4, #000045);
        background-size: 400% 400%;
        animation: gradient 20s ease infinite;

        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .animatedLeft {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .leftContent {
        height: auto;
        width: 100%;
        display: flex;
        user-select: none;
        justify-content: center;
        margin-top: -10rem;
        margin-bottom: 3rem;

        .logoEsq {
            margin: 0 auto 0 auto;
            width: 80%;
            margin-bottom: 1rem;
        }

        .text {
            font-size: 0.8rem;
            font-family: "Poppins", sans-serif;
            color: white;
            font-weight: 300;
            text-align: center;
        }

        p {
            color: white;
        }
    }

    .iconLogo {
        width: 20%;
        margin-bottom: 1.5rem;
    }

    .login-wrapper {
        background-color: rgba(0, 0, 69, 0.5);
        border-radius: 30px;
        width: 100%;
        height: auto !important;
    }

    .login-footer {
        padding-bottom: 0.5rem !important;
        display: flex;
        position: absolute;
        user-select: none;
        bottom: 0rem !important;
        padding: 1rem;
        color: white;
    }

    .contentMobile {
        height: calc(100vh - 242px);
        display: flex;
        align-items: center;
    }
}

@media only screen and (min-width: 700px) {
    @-webkit-keyframes slide-bottom {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(100px);
            transform: translateY(100px);
        }
    }
    @keyframes slide-bottom {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(-1800px);
        }
        100% {
            -webkit-transform: translateY(100px);
            transform: translateY(00px);
        }
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes slide-top {
        0% {
            transform: translateY(1800px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    .banner {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .banner video {
        position: fixed;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
    }

    .image {
        display: flex;
        position: absolute;
        opacity: 0.08;
        overflow: hidden;
        width: 100%;
    }

    .animatedLeft {
        -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .animationBackground {
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(271deg, #101063, #000045, #1717b4, #1d1d5d, #242471, #1717b4, #000045);
        background-size: 400% 400%;
        animation: gradient 20s ease infinite;
    }

    .leftContent {
        height: 90%;
        width: 60%;
        display: flex;
        user-select: none;
        justify-content: center;

        .logoEsq {
            margin: 0 auto 0 auto;
            width: 85%;
            margin-bottom: 2rem;
        }

        .text {
            font-size: 1.5vw;
            font-family: "Poppins", sans-serif;
            color: white;
            font-weight: 300;
            text-align: center;
        }

        p {
            color: white;
        }
    }

    .iconLogo {
        width: 20%;
        margin-bottom: 1.5rem;
    }

    .contentLogin {
        width: 75% !important;
        max-width: 75% !important;
    }

    .contentLoginButton {
        width: 75% !important;
        max-width: 75% !important;
        border: none !important;
    }

    .login-panel {
        width: 100% !important;
    }

    .login-wrapper {
        display: flex !important;
        justify-content: space-around !important;
        position: relative;
        background-color: rgba(0, 0, 69, 0.5);
        border-radius: 30px;
        min-width: 30rem;
        width: 30%;
        height: 90% !important;
        -webkit-animation: slide-top 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-top 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    .login-footer {
        padding-bottom: 0.5rem !important;
        display: flex;
        position: absolute;
        user-select: none;
        bottom: 0 !important;
        padding: 1rem;
        color: white;
    }
}
